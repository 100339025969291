import React from "react";
import "./ManualView.scss";
import { Field, Control, Label, Input } from 'react-bulma-components/lib/components/form';
import {Button} from 'react-bulma-components';
import {Redirect} from 'react-router-dom';

class ManualView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      regnum: '',
      redirect: undefined,
      resultText: ''
    }

    this.submit = this.submit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  submit(e){
    e.preventDefault();
    fetch(process.env.REACT_APP_API_URL + "/api/licensereader/manual?licenseNumber=" + this.state.regnum,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
    }).then((res) => {
      res.json().then(response => {
        if(response.success){
          this.setState({resultText: "Eier av bilen er: " + response.message, name: '', regnum: ''});
        }
        else{
          this.setState({resultText: response.message});
        }
        
      })
    }).catch(() => {
      this.setState({resultText: 'Noe gikk galt! Hør med Vegard :)'});
    })
  }

  goBack(e){
    e.preventDefault();
    this.setState({
      redirect: true
    })
  }

  render() {
    if(this.state.redirect)
      return <Redirect push to="/"></Redirect>
    return (
      <div className="registerview">
        <form className="regform" onSubmit={this.submit}>
          <Field>
            <Label  className="has-text-light" >Registreringsnummer</Label>
            <Control>
              <Input placeholder="UB12345" required pattern="\w{2}\s?\d{5}" title="Støtter bare 'vanlige' skilt, typ UB12345" onChange={(e) => this.setState({regnum: e.target.value})} value={this.state.regnum}/>
            </Control>
          </Field>
          <Button style={{marginTop: '1em'}} className="is-primary">Søk</Button>
          <Button onClick={this.goBack}style={{marginTop: '2em'}} className="is-primary">Tilbake</Button>
          <span>{this.state.resultText}</span>
        </form>

      </div>
    );
  }
}

export default ManualView;
