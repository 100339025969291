import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Home from './components/views/HomeView';
import CameraView from './components/views/CameraView';
import RegisterView from './components/views/RegisterView';
import ManualView from './components/views/ManualView';
import './App.scss';
import 'react-bulma-components/dist/react-bulma-components.min.css';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }
 
  render () {
    return (
      <div className="App">
        <Router>
          <Switch>
          <Route path="/manual">
                <ManualView {...this.props}/>
            </Route>
            <Route path="/register">
                <RegisterView {...this.props}/>
            </Route>
            <Route path="/check">
                <CameraView {...this.props}/>
            </Route>
            <Route path="/">
              <Home {...this.props}/>
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;