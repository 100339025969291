import React from 'react';
import './HomeView.scss';
import { Button } from 'react-bulma-components';
import { Redirect } from 'react-router-dom';

class HomeView extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      redirect: undefined
    }
  }

  redirect(redirect){
    this.setState({redirect});
  }

  render(){
    if(this.state.redirect){
      return <Redirect push to={this.state.redirect}/>
    }
    return (
      <div className="homeview">
        <div className="selections">
          <Button onClick={() => this.redirect("/check")} className="checkregnum" size="large" color="primary">Scan registreringsnummer</Button>
          <Button onClick={() => this.redirect("/manual")} className="checkregnum" size="large" color="primary">Manuell inntasting</Button>
          <Button onClick={() => this.redirect("/register")}size="small" color="primary">Registrer nytt</Button>
        </div>
      </div>
    )
  }
}

export default HomeView;