import React from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Button } from 'react-bulma-components';
import { Redirect } from 'react-router-dom';
import './CameraView.scss';

class CameraView extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          text: '',
          redirect: false,
          mirror: false
        };
        this.loading = false;
      }
      onTakePhoto (dataUri) {
        // Do stuff with the dataUri photo...
        if(this.loading) return;
        this.setState({text: 'Vent litt :)'})
        var data = {dataURI: dataUri};
        this.loading = true;
        fetch(process.env.REACT_APP_API_URL + "/api/licensereader", {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          mode: 'cors',
          body: JSON.stringify(data)
        }).then(res => {
          this.loading = false;
            res.json().then(response => {
              if(response.success){
                this.setState({text: "Eier av bilen er: " + response.message})
              }
              else{
                this.setState({text: response.message})
              }
                
            })
        }).catch(err => {
          console.error(err);
        }) 
      }
     
      render () {
        if(this.state.redirect){
          return <Redirect push to="/" />
        }

        return (
          <div className="cameraview">
            <Camera
              onTakePhoto = { (dataUri) => { this.onTakePhoto(dataUri); } }
              idealFacingMode = {FACING_MODES.ENVIRONMENT}
              isImageMirror = {this.state.mirror}
            />
            {this.state.text.length > 0 ? <p>{this.state.text}</p> : "Ta bilde av skiltet til bilen"}
            <div>
              <Button onClick={() => this.setState({mirror: !this.state.mirror})} className="is-primary">Toggle Kenneth Mode</Button>
              &nbsp;
              <Button onClick={() => this.setState({redirect: true})} className="is-primary">Tilbake</Button>
            </div>
          </div>
        );
      }
}

export default CameraView;