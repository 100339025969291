import React from "react";
import "./RegisterView.scss";
import { Field, Control, Label, Input, Checkbox } from 'react-bulma-components/lib/components/form';
import {Button} from 'react-bulma-components';
import {Redirect} from 'react-router-dom';

class RegisterView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      regnum: '',
      redirect: undefined
    }

    this.submit = this.submit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  submit(e){
    e.preventDefault();
    const obj = {
      owner: this.state.name,
      regNum: this.state.regnum
    }
    fetch(process.env.REACT_APP_API_URL + "/api/licensereader/new", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(obj)
    }).then((res) => {
      res.json().then(response => {
        if(response.success){
          this.setState({resultText: response.message, name: '', regnum: ''});
        }
        else{
          this.setState({resultText: response.message});
        }
        
      })
    }).catch(() => {
      this.setState({resultText: 'Noe gikk galt! Hør med Vegard :)'});
    })
  }

  goBack(e){
    e.preventDefault();
    this.setState({
      redirect: true
    })
  }

  render() {
    if(this.state.redirect)
      return <Redirect push to="/"></Redirect>
    return (
      <div className="registerview">
        <form className="regform" onSubmit={this.submit}>
          <Field>
            <Label className="has-text-light" >Eier</Label>
            <Control>
              <Input placeholder="Ola Nordmann" required onChange={(e) => this.setState({name: e.target.value})} value={this.state.name}  />
            </Control>
          </Field>
          <Field>
            <Label  className="has-text-light" >Registreringsnummer</Label>
            <Control>
              <Input placeholder="UB12345" required pattern="\w{2}\s?\d{5}" title="Støtter bare 'vanlige' skilt, typ UB12345" onChange={(e) => this.setState({regnum: e.target.value})} value={this.state.regnum}/>
            </Control>
          </Field>
          <Field>
            <Label className="has-text-light">Jeg godtar at Registeringsnummer og Navn vil bli lagret, og kan vises til andre når de vil vite hvem som eier en bil. Hvis jeg i ettertid skulle angre, kan jeg kontakte Vegard for å få det fjernet</Label>
            <Control>
              <Checkbox required onChange={(e) => this.setState({comply: e.target.value})} value={this.state.comply} />
            </Control>
          </Field>
          <Button style={{marginTop: '1em'}} className="is-primary">Registrer</Button>
          <Button onClick={this.goBack}style={{marginTop: '2em'}} className="is-primary">Tilbake</Button>
          <span>{this.state.resultText}</span>
        </form>

      </div>
    );
  }
}

export default RegisterView;
